import { useInsertPostInterviewQuestions } from "model/queryCalls/queryCalls";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";

const PostInterview: React.FC = () => {
  const location = useLocation();

  const { jobId, organizationId, interviewId } = location.state || {};

  const [salary, setSalary] = useState("");
  const [preference, setPreference] = useState("");
  const [selectedDate, setSelectedDate] =
    useState<string>("04 September, 2024");
  const [selectedTime] = useState<string>("10:00 AM");

  const { mutate: submitPostInterview, isLoading } =
    useInsertPostInterviewQuestions();

  const handleDateSelect = (day: string) => {
    setSelectedDate(`${day} September, 2024`);
  };

  const handleSubmit = () => {
    const questions = [
      { question: "What is your expected salary?", answer: salary },
      { question: "Do you prefer WFH or WFO?", answer: preference },
    ];

    submitPostInterview({
      jobId,
      organizationId,
      interviewId,
      questions,
      selectedDate,
      selectedTime,
    });
  };

  return (
    <div className="flex min-h-screen flex-col bg-gray-50 p-8 md:flex-row">
      <div className="flex flex-col items-center justify-center bg-gray-100 p-8 text-center md:w-1/2">
        <h1 className="mb-4 text-5xl font-bold text-gray-800">
          Thank You for attending
        </h1>
        <p className="text-lg text-gray-600">Please fill pre-screening data</p>
      </div>

      <div className="p-8 md:w-1/2">
        <div className="space-y-6 rounded-lg bg-white p-6 shadow-lg">
          <h2 className="text-2xl font-semibold text-gray-700">Questions</h2>

          <div>
            <label className="text-md mb-2 block text-gray-800">
              What is your expected salary?
            </label>
            <textarea
              rows={3}
              placeholder="Enter your expected salary"
              value={salary}
              onChange={(e) => setSalary(e.target.value)}
              className="w-full rounded-lg border border-gray-300 p-4 text-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>

          <div>
            <label className="text-md mb-2 block text-gray-800">
              Do you prefer WFH or WFO?
            </label>
            <textarea
              rows={3}
              placeholder="Enter your preference"
              value={preference}
              onChange={(e) => setPreference(e.target.value)}
              className="w-full rounded-lg border border-gray-300 p-4 text-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
        </div>

        <div className="mt-6 grid grid-cols-2 gap-4">
          <div className="rounded-lg bg-white p-4 shadow-lg">
            <div className="mb-4 flex items-center justify-between">
              <button className="text-purple-600">❮</button>
              <h3 className="text-lg font-bold text-gray-700">
                September 2024
              </h3>
              <button className="text-purple-600">❯</button>
            </div>
            <div className="grid grid-cols-7 gap-2 text-center text-gray-600">
              <span>MON</span> <span>TUE</span> <span>WED</span>{" "}
              <span>THU</span> <span>FRI</span> <span>SAT</span>{" "}
              <span>SUN</span>
              {Array.from({ length: 30 }).map((_, i) => (
                <button
                  key={i}
                  onClick={() => handleDateSelect((i + 1).toString())}
                  className={`${
                    i + 1 === parseInt(selectedDate.split(" ")[0])
                      ? "bg-blue-500 text-white"
                      : "text-gray-800"
                  } rounded-lg p-3 hover:bg-blue-100 focus:bg-blue-500 focus:text-white`}
                >
                  {i + 1}
                </button>
              ))}
            </div>
          </div>

          <div className="flex flex-col items-center justify-center rounded-lg bg-white p-6 shadow-lg">
            <p className="mb-2 text-center text-lg text-gray-700">
              You have chosen{" "}
              <strong className="font-bold">{selectedDate}</strong> at{" "}
              <strong className="font-bold">{selectedTime}</strong> to be the
              convenient time for your interview.
            </p>
          </div>
        </div>

        <button
          onClick={handleSubmit}
          className="mt-6 w-full rounded-lg bg-blue-500 p-4 text-lg font-bold text-white"
          disabled={isLoading}
        >
          {isLoading ? "Submitting..." : "Submit"}
        </button>
      </div>
    </div>
  );
};

export default PostInterview;
