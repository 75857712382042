import { useMutation, useQuery } from "react-query";
import { useContext } from "react";
import { UserContext } from "controller/context/userContext";
import {
  getCustomers,
  getOneCustomer,
  getOneUser,
  getUsers,
} from "controller/services/userServices";
import {
  addOrganizationToUser,
  getJobById,
  getJobsByOrganization,
  getUserIdByEmail,
  insertPostInterviewQuestions,
  sendPDFContent,
} from "controller/services/dbServices";

/**
 * Hook to fetch jobs by organization ID.
 */
export const useGetJobsByOrganization = (organizationId: string) => {
  return useQuery(
    ["jobs", organizationId],
    () => getJobsByOrganization(organizationId),
    {
      enabled: !!organizationId,
      onError: (error: unknown) => {
        console.error("Failed to fetch jobs:", error);
      },
    }
  );
};

/**
 * Hook to fetch a single job by its ID.
 */
export const useGetJobById = (jobId: string) => {
  return useQuery(["job", jobId], () => getJobById(jobId), {
    enabled: !!jobId,
    onError: (error: unknown) => {
      console.error("Failed to fetch job:", error);
    },
  });
};

/**
 * Hook to fetch user ID by email.
 */
export const useGetUserIdByEmail = (email: string) => {
  return useQuery(["getUserIdByEmail", email], () => getUserIdByEmail(email), {
    enabled: !!email,
    onError: (error: unknown) => {
      console.error("Failed to fetch user ID:", error);
    },
  });
};

/**
 * Hook to add an organization to a user profile.
 */
export const useAddOrganizationToUser = () => {
  return useMutation(
    (params: { userId: string; organizationId: string }) =>
      addOrganizationToUser(params.userId, params.organizationId),
    {
      onError: (error: unknown) => {
        console.error("Failed to add organization to user:", error);
      },
    }
  );
};

/**
 * Hook to send PDF content for processing.
 */
export const useSendPDFContent = () => {
  return useMutation(
    (params: { content: string; userId: string | undefined }) => {
      return sendPDFContent(params.content, params.userId);
    },
    {
      onError: (error: unknown) => {
        console.error("Failed to send PDF content:", error);
      },
    }
  );
};

/**
 * Hook to fetch the users list.
 */
export const useUsersList = ({
  page,
  rowsPerPage,
}: {
  page: number;
  rowsPerPage: number;
}) => {
  const { user } = useContext(UserContext);
  return useQuery(
    ["usersData", user, page, rowsPerPage],
    () => getUsers({ page, rowsPerPage }),
    {
      onError: (error: unknown) => {
        console.error("Failed to fetch users:", error);
      },
    }
  );
};

/**
 * Hook to fetch details of a single user by ID.
 */
export const useOneUser = ({ id }: { id: string }) => {
  const { user } = useContext(UserContext);
  return useQuery(["oneUser", user, { id }], () => getOneUser({ id }), {
    enabled: !!id,
    onError: (error: unknown) => {
      console.error("Failed to fetch user details:", error);
    },
  });
};

/**
 * Hook to fetch the customers list.
 */
export const useCustomers = ({
  page,
  rowsPerPage,
}: {
  page: number;
  rowsPerPage: number;
}) => {
  const { user } = useContext(UserContext);
  return useQuery(
    ["customerData", user, page, rowsPerPage],
    () => getCustomers({ page, rowsPerPage }),
    {
      onError: (error: unknown) => {
        console.error("Failed to fetch customers:", error);
      },
    }
  );
};

/**
 * Hook to fetch details of a single customer by ID.
 */
export const useOneCustomer = ({ id }: { id: string }) => {
  const { user } = useContext(UserContext);
  return useQuery(["oneCustomer", user, { id }], () => getOneCustomer({ id }), {
    enabled: !!id,
    onError: (error: unknown) => {
      console.error("Failed to fetch customer details:", error);
    },
  });
};
/**
 * Hook for Inserting Post-Interview Questions.
 */
export const useInsertPostInterviewQuestions = () => {
  return useMutation(
    (params: {
      jobId: string;
      organizationId: string;
      interviewId: string;
      questions: { question: string; answer: string }[];
      selectedDate: string;
      selectedTime: string;
    }) => insertPostInterviewQuestions(params),
    {
      onError: (error: unknown) => {
        console.error("Failed to insert post-interview questions:", error);
      },
    }
  );
};
