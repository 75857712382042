import React from "react";

// Admin Imports
import MainDashboard from "views/admin/default";
import DataTables from "views/admin/tables";

import Users from "views/admin/users";
import AddUsers from "views/admin/users/addUsers";

import AddRoles from "views/admin/roles/addRoles";

// Auth Imports
import SignIn from "views/auth/SignIn";
import Jobs from "views/admin/jobs";
import AboutCompany from "components/aboutCompany/AboutCompany";
import JobDescription from "views/admin/jobDescription";
import StartInterview from "views/admin/startInterview";
import Interview from "views/admin/Interview";
import PostInterview from "views/admin/postInterview";
// import Interview from "components/Interview";

const routes = [
  {
    name: "Dashboard",
    layout: "/admin",
    path: "default",
    component: <MainDashboard />,
  },
  {
    name: "SignIn",
    layout: "/admin",
    path: "signIn",
    component: <SignIn />,
  },
  {
    name: "Jobs",
    layout: "/admin",
    path: "jobs",
    component: <Jobs />,
  },
  {
    name: "Jobs",
    layout: "/admin",
    path: "jobs",
    component: <Jobs />,
  },
  {
    name: "Start Interview",
    layout: "/admin",
    path: "start-interview",
    component: <StartInterview />,
  },
  {
    name: "Post Interview",
    layout: "/admin",
    path: "post-interview",
    component: <PostInterview />,
  },
  {
    name: "AboutCompany",
    layout: "/admin",
    path: "about-company",
    component: <AboutCompany />,
  },
  {
    name: "Interview",
    layout: "/admin",
    path: "interview/:id",
    component: <Interview />,
  },
  {
    name: "Job Description",
    layout: "/admin",
    path: "job/:id",
    component: <JobDescription />,
  },

  {
    name: "Data Tables",
    layout: "/admin",
    path: "data-tables",
    component: <DataTables />,
  },
  {
    name: "Sign In",
    layout: "/auth",
    path: "sign-in",
    component: <SignIn />,
  },
  {
    name: "All Users",
    layout: "/admin",
    path: "all-users",
    component: <Users />,
  },
  {
    name: "Add Users",
    layout: "/admin",
    path: "add-users",
    component: <AddUsers />,
  },
  {
    name: "Edit Users",
    layout: "/admin",
    path: "edit-users/:id",
    component: <AddUsers />,
  },
  {
    name: "Add Roles",
    layout: "/admin",
    path: "add-roles",
    component: <AddRoles />,
  },
];
export default routes;
