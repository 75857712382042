import PDFReader from "react-pdftotext";
import { FormData, SendPDFResponse } from "types/jobApplicationModalTypes";
import { populateFormData } from "./jobApplicationUtils";

export const extractPDFContent = async (
  file: File,
  sendContentToBackend: (content: string) => void
) => {
  try {
    const pdfText = await PDFReader(file);
    sendContentToBackend(pdfText);
  } catch (error) {
    console.error("Error extracting PDF content:", error);
  }
};

export const sendContentToBackend = (
  content: string,
  userId: string,
  formData: any,
  setFormData: React.Dispatch<React.SetStateAction<FormData>>,
  sendPDF: (params: { content: string; userId: string }, options: any) => void
) => {
  sendPDF(
    { content, userId },
    {
      onSuccess: (data: SendPDFResponse) => {
        if (data && data.extractedInfo) {
          const updatedFormData = populateFormData(data.extractedInfo);
          console.log('formData', formData)
          setFormData({
            fullName: updatedFormData.fullName || "",
            jobTitle: formData.jobTitle || "",
            experience: updatedFormData.experience || "Fresher",
            jobDescription: formData.jobDescription || "",
          });
          // setFormData(updatedFormData);
        } else {
          console.error("Unexpected response format:", data);
        }
      },
      onError: (error: any) => {
        console.error("Error sending content to backend:", error);
      },
    }
  );
};
