import * as Realm from "realm-web";
import envConfig from "../../env/env.json";
import { Job } from "types/job";
import { UserIdResponse, OrganizationResponse } from "types/user";

export const app = new Realm.App({ id: envConfig.MONGODB_APP_ID });

export const {
  BSON: { ObjectId },
} = Realm;
export const getDBInstance = () => {
  if (!app || !app.currentUser) {
    return;
  }
  return app.currentUser.mongoClient("mongodb-atlas").db(envConfig.MONGODB_DB);
};

export const callMongoFunction = async <T>(
  fnName: string,
  payload: object
): Promise<T> => {
  try {
    const response = await app.currentUser.callFunction(fnName, payload);
    return response as T;
  } catch (error: any) {
    console.error(`Error calling ${fnName}:`, error.message);
    throw new Error(`Failed to execute ${fnName}`);
  }
};

/**
 * Get interview questions and audio content from MongoDB Realm.
 */
export const getInterviewQuestions = async (
  fullName: string,
  jobTitle: string,
  experience: string,
  jobDescription: string,
  interviewId: string,
  userId: string,
  candidateAnswer: string
): Promise<{ questions: string; audioContent: string }> => {
  return callMongoFunction<{ questions: string; audioContent: string }>(
    "getInterviewQuestions",
    {
      fullName,
      jobTitle,
      experience,
      jobDescription,
      interviewId,
      userId,
      candidateAnswer,
    }
  );
};

/**
 * Get jobs by organization ID.
 */
export const getJobsByOrganization = async (
  organizationId: string
): Promise<Job[]> => {
  if (!organizationId) throw new Error("Organization ID required");
  return callMongoFunction<Job[]>("getJobsByOrganization", { organizationId });
};

/**
 * Get job by job ID.
 */
export const getJobById = async (jobId: string): Promise<Job> => {
  if (!jobId) throw new Error("Job ID required");
  return callMongoFunction<Job>("getJobById", { jobId });
};

/**
 * Get user ID by email.
 */
export const getUserIdByEmail = async (
  email: string
): Promise<UserIdResponse> => {
  if (!email) throw new Error("Email required");

  const response = await callMongoFunction<UserIdResponse>("getUserByEmail", {
    email,
  });
  if (!response.userId) throw new Error("User not found");

  return response;
};

/**
 * Add organization to user profile.
 */
export const addOrganizationToUser = async (
  userId: string,
  organizationId: string
): Promise<OrganizationResponse> => {
  if (!userId || !organizationId)
    throw new Error("userId and organizationId required");

  const response = await callMongoFunction<OrganizationResponse>(
    "updateUserOrganizations",
    {
      userId,
      organizationId,
    }
  );

  if (!response.success)
    throw new Error(response?.message || "Failed to add organization");
  return response;
};

/**
 * Send PDF content for extraction.
 */
export const sendPDFContent = async (
  content: string,
  userId?: string
): Promise<{ extractedInfo: string }> => {
  if (!content) throw new Error("PDF content required");

  return callMongoFunction<{ extractedInfo: string }>("contentExtract", {
    fileContent: content,
    userId,
  });
};
/**
 * Function to insert post-interview questions.
 */
export const insertPostInterviewQuestions = async ({
  jobId,
  organizationId,
  interviewId,
  questions,
  selectedDate,
  selectedTime,
}: {
  jobId: string;
  organizationId: string;
  interviewId: string;
  questions: { question: string; answer: string }[];
  selectedDate: string;
  selectedTime: string;
}): Promise<{ success: boolean; message: string }> => {
  return callMongoFunction<{ success: boolean; message: string }>(
    "insertPostInterviewQuestions",
    {
      jobId,
      organizationId,
      interviewId,
      questions,
      selectedDate,
      selectedTime,
    }
  );
};

