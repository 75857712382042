import React, { useRef, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { app } from "controller/services/dbServices";
import { v4 as uuidv4 } from "uuid";
import { FormData, JobApplicationModalProps } from "types/jobApplicationModalTypes";
import { useSendPDFContent } from "model/queryCalls/queryCalls";
import { extractPDFContent, sendContentToBackend } from "controller/common/pdfHandler";

export const JobApplicationModal: React.FC<JobApplicationModalProps> = ({
  isOpen,
  onClose,
  jobDetails,
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [formData, setFormData] = useState<FormData>({
    fullName: "",
    jobTitle: jobDetails.title || "",
    experience: "",
    jobDescription: jobDetails.description || "",
  });

  const navigate = useNavigate();
  const userId = app?.currentUser?.id || "";

  const { mutate: sendPDF, isLoading } = useSendPDFContent();

  const handleUploadClick = useCallback(() => {
    fileInputRef.current?.click();
  }, []);

  const handleFileChange = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0];
      if (file) {
        setLoading(true);
        setSelectedFile(file);
        await extractPDFContent(file, (pdfText) =>
          sendContentToBackend(pdfText, userId, formData, setFormData, sendPDF)
        );
        setLoading(false);
      }
    },
    [userId, sendPDF]
  );

  const handleRemoveFile = useCallback(() => {
    setSelectedFile(null);
    setFormData({
      fullName: "",
      jobTitle: jobDetails.title || "",
      experience: "",
      jobDescription: jobDetails.description || "",
    });
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  }, []);

  const handleContinue = useCallback(() => {
    const interviewId = uuidv4();

    navigate("/admin/start-interview", {
      state: {
        fullName: formData.fullName,
        jobTitle: formData.jobTitle,
        experience: formData.experience,
        jobDescription: formData.jobDescription,
        interviewId,
      },
    });

    onClose();
  }, [formData, navigate, onClose]);

  if (!isOpen) return null;

  return (
    <div
      className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-75"
      role="dialog"
      aria-labelledby="modal-title"
    >
      <div className="w-full max-w-4xl rounded-lg border border-gray-300 bg-white shadow-lg">
        <div className="p-8">
          <div className="mb-6 flex items-center justify-between">
            <h2 id="modal-title" className="text-xl font-bold text-gray-400">
              Application
            </h2>
            <button
              onClick={onClose}
              className="text-gray-500 hover:text-gray-700 focus:outline-none"
              aria-label="Close modal"
            >
              <span className="text-2xl">&times;</span>
            </button>
          </div>
          <form>
            <div className="mb-6">
              {selectedFile ? (
                <div className="flex w-full items-center rounded-lg border border-gray-300 bg-gray-100 px-4 py-3 shadow-md">
                  <span className="flex-grow font-medium text-gray-700">
                    {selectedFile.name}
                  </span>
                  <button
                    type="button"
                    onClick={handleRemoveFile}
                    className="ml-4 text-red-500 hover:text-red-700 focus:outline-none"
                  >
                    <span className="text-2xl font-bold">&times;</span>
                  </button>
                </div>
              ) : (
                <>
                  <input
                    type="file"
                    ref={fileInputRef}
                    onChange={handleFileChange}
                    className="hidden"
                    accept=".pdf,.doc,.docx"
                    aria-label="Upload resume"
                  />
                  <button
                    type="button"
                    className="flex w-full items-center justify-center rounded-lg bg-blue-600 py-3 font-bold text-white shadow-md transition duration-300 ease-in-out hover:bg-blue-700"
                    onClick={handleUploadClick}
                    disabled={loading || isLoading}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="mr-2 h-5 w-5"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M12 4v16m8-8H4"
                      />
                    </svg>
                    Upload Resume
                  </button>
                </>
              )}
              {(loading || isLoading) && (
                <p className="mt-2 animate-pulse text-blue-600">
                  Extracting resume content...
                </p>
              )}
            </div>

            <h2 className="mb-6 text-xl font-bold text-gray-400">Details</h2>
            <div className="mb-6">
              <input
                type="text"
                placeholder="Full Name"
                className="w-full rounded-lg border border-gray-300 px-4 py-3 shadow-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                value={formData.fullName}
                onChange={(e) =>
                  setFormData((prev) => ({
                    ...prev,
                    fullName: e.target.value,
                  }))
                }
              />
            </div>
            <div className="mb-6">
              <input
                type="text"
                placeholder="Job Title"
                className="w-full rounded-lg border border-gray-300 px-4 py-3 shadow-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                value={formData.jobTitle}
                onChange={(e) =>
                  setFormData((prev) => ({
                    ...prev,
                    jobTitle: e.target.value,
                  }))
                }
              />
            </div>
            <div className="mb-6">
              <input
                type="text"
                placeholder="Experience"
                className="w-full rounded-lg border border-gray-300 px-4 py-3 shadow-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                value={formData.experience}
                onChange={(e) =>
                  setFormData((prev) => ({
                    ...prev,
                    experience: e.target.value,
                  }))
                }
              />
            </div>
            <div className="mb-6">
              <textarea
                placeholder="Job Description"
                className="w-full rounded-lg border border-gray-300 px-4 py-3 shadow-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                rows={5}
                value={formData.jobDescription}
                onChange={(e) =>
                  setFormData((prev) => ({
                    ...prev,
                    jobDescription: e.target.value,
                  }))
                }
              ></textarea>
            </div>
            <button
              type="button"
              className="w-full rounded-lg bg-blue-600 py-3 font-bold text-white shadow-md transition duration-300 ease-in-out hover:bg-blue-700"
              onClick={handleContinue}
              disabled={loading || isLoading}
            >
              Continue
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};
